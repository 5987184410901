// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
.footer {
    padding: 20px;
    text-align: center;
   
}

.footer-content p {
    margin: 6px 0;
    color: #ffffff;
    font-size: 14px;
}

.footer a {
    color: #ffffff;
    text-decoration: none;
}


.icon-link {
    margin-right: 10px; 
    margin-left:10px;/* Adjust space between icons */
    display: inline-block; /* Ensures margin applies correctly */
    vertical-align: middle; /* Aligns icons nicely if text is also in line */
  }
  
  .icon {
    font-size: 22px; /* Adjusts the size of the icons */
    color: #ffffff; /* Example to set the color, adjust as needed */
    margin-top:15px;
  }
  
  /* Specific styles for each icon type can be added if needed */
  .twitter {
    color: #ffffff; /* Twitter's brand color, adjust as needed */
  }
  
  .telegram {
    color: #ffffff; /* Telegram's brand color, adjust as needed */
  }
  
  .mail {
    color: #ffffff; /* Mail icon color, adjust as needed */
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,kBAAkB;;AAEtB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;;AAGA;IACI,kBAAkB;IAClB,gBAAgB,CAAC,+BAA+B;IAChD,qBAAqB,EAAE,qCAAqC;IAC5D,sBAAsB,EAAE,gDAAgD;EAC1E;;EAEA;IACE,eAAe,EAAE,kCAAkC;IACnD,cAAc,EAAE,+CAA+C;IAC/D,eAAe;EACjB;;EAEA,8DAA8D;EAC9D;IACE,cAAc,EAAE,4CAA4C;EAC9D;;EAEA;IACE,cAAc,EAAE,6CAA6C;EAC/D;;EAEA;IACE,cAAc,EAAE,sCAAsC;EACxD","sourcesContent":["/* Footer.css */\n.footer {\n    padding: 20px;\n    text-align: center;\n   \n}\n\n.footer-content p {\n    margin: 6px 0;\n    color: #ffffff;\n    font-size: 14px;\n}\n\n.footer a {\n    color: #ffffff;\n    text-decoration: none;\n}\n\n\n.icon-link {\n    margin-right: 10px; \n    margin-left:10px;/* Adjust space between icons */\n    display: inline-block; /* Ensures margin applies correctly */\n    vertical-align: middle; /* Aligns icons nicely if text is also in line */\n  }\n  \n  .icon {\n    font-size: 22px; /* Adjusts the size of the icons */\n    color: #ffffff; /* Example to set the color, adjust as needed */\n    margin-top:15px;\n  }\n  \n  /* Specific styles for each icon type can be added if needed */\n  .twitter {\n    color: #ffffff; /* Twitter's brand color, adjust as needed */\n  }\n  \n  .telegram {\n    color: #ffffff; /* Telegram's brand color, adjust as needed */\n  }\n  \n  .mail {\n    color: #ffffff; /* Mail icon color, adjust as needed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
