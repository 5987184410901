// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TokensPage.css */

/* Desktop and tablet */
.token-list-mobile {
    display: none; /* Hide on desktop and tablet */
  }
  
  /* Mobile devices */
  @media only screen and (max-width: 768px) {
    .token-list-desktop {
      display: none; /* Hide on mobile */
    }
  
    .token-list-mobile {
      display: block; /* Show only on mobile */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/TokenListPage.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB,uBAAuB;AACvB;IACI,aAAa,EAAE,+BAA+B;EAChD;;EAEA,mBAAmB;EACnB;IACE;MACE,aAAa,EAAE,mBAAmB;IACpC;;IAEA;MACE,cAAc,EAAE,wBAAwB;IAC1C;EACF","sourcesContent":["/* TokensPage.css */\n\n/* Desktop and tablet */\n.token-list-mobile {\n    display: none; /* Hide on desktop and tablet */\n  }\n  \n  /* Mobile devices */\n  @media only screen and (max-width: 768px) {\n    .token-list-desktop {\n      display: none; /* Hide on mobile */\n    }\n  \n    .token-list-mobile {\n      display: block; /* Show only on mobile */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
