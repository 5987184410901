// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tasks-container {
    padding: 20px;
}

.deployedtaskstitle {
    font-size: 34px;
    margin-bottom: 10px;
    color: white; /* Set title text to white */
}

.subtitletasks {
    font-size: 18px;
    margin-bottom: 20px;
    color: white; /* Set subtitle text to white */
}

.tasks-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px; /* Space between cards */
}

.task-card {
    background-color: rgba(255, 255, 255, 0.1); /* Light background for cards */
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    color: white; /* Set text color to white */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

.task-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Button color */
    color: white; /* Button text color */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.task-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-image: url('https://i.imgur.com/EeSiiMV.jpeg'); /* Background image */
    background-size: cover;
    background-attachment: fixed;
}
`, "",{"version":3,"sources":["webpack://./src/components/TaskMobile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,YAAY,EAAE,4BAA4B;AAC9C;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,YAAY,EAAE,+BAA+B;AACjD;;AAEA;IACI,aAAa;IACb,4DAA4D;IAC5D,SAAS,EAAE,wBAAwB;AACvC;;AAEA;IACI,0CAA0C,EAAE,+BAA+B;IAC3E,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,YAAY,EAAE,4BAA4B;IAC1C,yCAAyC,EAAE,qBAAqB;AACpE;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB,EAAE,iBAAiB;IAC5C,YAAY,EAAE,sBAAsB;IACpC,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;AACzD;;AAEA;IACI,YAAY;IACZ,SAAS;IACT,UAAU;IACV,yDAAyD,EAAE,qBAAqB;IAChF,sBAAsB;IACtB,4BAA4B;AAChC","sourcesContent":[".tasks-container {\n    padding: 20px;\n}\n\n.deployedtaskstitle {\n    font-size: 34px;\n    margin-bottom: 10px;\n    color: white; /* Set title text to white */\n}\n\n.subtitletasks {\n    font-size: 18px;\n    margin-bottom: 20px;\n    color: white; /* Set subtitle text to white */\n}\n\n.tasks-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n    gap: 20px; /* Space between cards */\n}\n\n.task-card {\n    background-color: rgba(255, 255, 255, 0.1); /* Light background for cards */\n    border-radius: 10px;\n    padding: 15px;\n    text-align: center;\n    color: white; /* Set text color to white */\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */\n}\n\n.task-button {\n    padding: 5px 10px;\n    border: none;\n    border-radius: 5px;\n    background-color: #007bff; /* Button color */\n    color: white; /* Button text color */\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.task-button:hover {\n    background-color: #0056b3; /* Darker shade on hover */\n}\n\nhtml, body {\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    background-image: url('https://i.imgur.com/EeSiiMV.jpeg'); /* Background image */\n    background-size: cover;\n    background-attachment: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
