// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  }

code {
  font-family: "Space Grotesk", sans-serif;/* Updated font-family */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,wCAAwC;EACxC,yBAAyB;EACzB;;AAEF;EACE,wCAAwC,CAAC,wBAAwB;AACnE","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Space Grotesk\", sans-serif;\n  font-optical-sizing: auto;\n  }\n\ncode {\n  font-family: \"Space Grotesk\", sans-serif;/* Updated font-family */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
